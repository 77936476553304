<template>
  <div class="container">
    <Spinner />
    <div class="auto-form">
      <div v-show="auto.length" class="table-responsive auto-table">
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Объект</th>
              <th scope="col">Группа получателей</th>
              <th colspan="3" scope="col">Параметры</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, ind) in auto" :key="ind">
              <th scope="row">{{ind + 1}}</th>
              <td>{{objects[item.object]}}</td>
              <td>{{getListName(item.list)}}</td>
              <td colspan="3">{{getParams(item.params, item.object)}}</td>
              <td>
                <div class="btn-group">
                  <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  </button>
                  <div class="dropdown-menu">
                    <a class="dropdown-item" data-toggle="modal"
          data-target=".bd-example-modal-lg" @click="edit(item.id, item.object)">Редактировать</a>
                    <a class="dropdown-item" @click="remove(item.id)">Удалить</a>
                  </div>
                  </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-show="!auto.length" class="row">
        <h5>
          Добавьте автоматизацию, чтобы нужные данные автоматически загружались
          в NotiSend
        </h5>
      </div>
      <div class="row">
        <button
          type="submit"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
          class="btn add-auto-btn"
        >
          Добавить
        </button>
      </div>
      <ModalExportForm v-show="true" />
    </div>
  </div>
</template>

<script>
import ModalExportForm from "../components/ModalExportForm.vue";
import Spinner from "../components/Spinner.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  data() {
    return {
      objects: {
        'lead': 'Лиды',
        'company': 'Компании',
        'contact': 'Контакты',
        'deal': 'Сделки',
      }
    }
  },
  components: {
    ModalExportForm,
    Spinner
  },
  computed: {
    ...mapGetters(['exportSelects', 'auto', 'params'])
  },
  methods: {
    ...mapMutations(['updateCurrentAuto']),
    ...mapActions(['deleteAutoById', 'changeCurrentObject']),
    getListName(id) {
      const listName = this.exportSelects.list.ITEMS.find(item => item.VALUE == id);
      return listName ? listName.NAME : "";
    },
    getParams(params, object) {
      return params.reduce((acc, item) => {
        if(this.params[object][item]) {
          acc.push(this.params[object][item].title);
        }
        return acc;
      }, []).join(", ");
    },
    async edit(id, obj) {
      this.changeCurrentObject(obj);
      this.updateCurrentAuto(id);
    }, 
    async remove(id) {
      await this.deleteAutoById(id);
    }
  }
};
</script>

<style>
  .add-auto-btn {
    min-width: 100px;
  }
</style>
