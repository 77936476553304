<template>
<div class="row col params">
    <div class="form-check col-4 text-start"
        v-for="(item, index) in properties" 
        :key="index" :value="item.VALUE">
        <input class="form-check-input" type="checkbox" :checked="isCheckedProperty(item.VALUE)" :value="item.VALUE" @change="selectParams">
        <label class="form-check-label" :for="item.VALUE">{{item.NAME}}</label> 
    </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
    name: "MultiSelect",
    props: {
        properties: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters(['currentAutoId'])
    },
    methods: {
        ...mapActions(["updateParams"]),
        selectParams(event) {
            this.updateParams(event.target);
        },
        isCheckedProperty(val) {
            let isInclude = this.currentAutoId.params ? this.currentAutoId.params.includes(val) : false;
            return isInclude;
        }
    }
}
</script>
