<template>
 <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Добавьте автоматизацию</h5>
        <button type="button" class="close" @click="resetCurrentAuto" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <ExportForm 
        :type="'auto'"
        />
      </div>
    </div>
  </div>
</div>
</template>

<script>
import ExportForm from './ExportForm.vue';
import {mapMutations} from 'vuex';

export default {
    components: {
        ExportForm
    },
    methods: {
      ...mapMutations(['resetCurrentAuto'])
    }
}
</script>

<style scoped>

</style>
