<template>
  <div class="form-group row">
    <div class="row">
      <div class="col-1">
        <img :src="select.IMG" />
      </div>
      <div class="col">
        <h5 class="text-start">{{ select.NAME }}</h5>
        <h6 class="text-start">
          <small>{{ select.DESCRIPTION }}</small>
        </h6>
      </div>
    </div>
    <div class="form-group row">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <label class="input-group-text" :for="select.VALUE">{{ select.NAME }}</label
          >
        </div>
        <select v-if="!isMulti" @change="changeSelect($event)" class="custom-select" :name="select.VALUE" :id="select.VALUE">
          <option :selected="isSelectedValue(item.VALUE)"
            v-for="(item, index) in select.ITEMS" 
            :key="index" :value="item.VALUE">
            {{item.NAME}}</option>
        </select>
        <MultiSelect v-else
          :properties="select.ITEMS"
          :key="multiSelectKey"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapGetters} from 'vuex';
import MultiSelect from "../components/MultiSelect.vue";
    export default {
        name: "Select",
        components: {
          MultiSelect
        },
        props: {
            select: Object,
            dependSelect: Boolean,
            isMulti: Boolean
        },
        computed: {
          ...mapGetters([
            "multiSelectKey", "currentAutoId", "auto"
          ]),
        },
        methods: {
          ...mapActions(["changeCurrentObject"]),
          ...mapMutations(["updateSelectedObject", "updateSelectedList"]),
          async onChange(value) {
            if(this.select.VALUE == "object") {
              await this.changeCurrentObject(value);
            }
          },
          async changeSelect(event) {
            if(this.select.VALUE == 'object') {
              this.updateSelectedObject(event.target.value);
              await this.onChange(event.target.value);
            } else if(this.select.VALUE == 'list') {
              this.updateSelectedList(event.target.value);
            }
          },
          isSelectedValue(val) {
            return this.currentAutoId[this.select.VALUE] == val;
          }
        }
    };
</script>
