<template>
    <div class="container">
        <div class="export-form">
            <div class="row">
                <div>
                    <Select 
                        :dependSelect="true"
                        :select="exportSelects.object"
                        :isMulti="false"
                    />
                    <Select 
                        :dependSelect="false"
                        :select="exportSelects.list"
                        :isMulti="false"
                    />
                    <Select 
                        :dependSelect="false"
                        :select="exportSelects.params"
                        :isMulti="true"
                    />
                    <button @click="sendExportForm(type)" data-dismiss="modal" aria-label="Close" class="btn">Экспортировать</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Select from "../components/Select";
import Spinner from "../components/Spinner";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ExportForm",
  components: {
    Select,
    Spinner
  },
  props: {
    type: {
      type: String,
      required: false
    }
  },
  computed: {
    ...mapGetters(["exportSelects"]),
  },
  methods: {
    ...mapActions(['sendExportForm'])
  }
};
</script>
