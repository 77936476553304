<template>
    <div v-show="isSpinnerVisible" class="spin spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['isSpinnerVisible'])
  },
}
</script>


<style>
    .spin {
        position: fixed;
        z-index: 100;
        width: 3rem;
        height: 3rem;
        opacity: 60% !important;
    }
</style>